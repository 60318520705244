import firebase from 'firebase'


// Firebase Configuration
const config = {
    apiKey: "AIzaSyDSIE2JDPz8WRGSP7ehqsIc5QMFh5JDr3Y",
    authDomain: "flikbox-2606.firebaseapp.com",
    projectId: "flikbox-2606",
    storageBucket: "flikbox-2606.appspot.com",
    messagingSenderId: "195515976423",
    appId: "1:195515976423:web:81a3bb84911f2d33183042",
    measurementId: "G-GG83DKG7QK"
};

// Firebase Initialization
//const firebaseApp = firebase.initializeApp(config);
if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

// Defining Firestore Collections
const db = firebase.firestore();
const userOrdersCollection = db.collection("userOrders");
const ordersCollection = db.collection("orders");


// Function to get product data from firestore
export const getuserOrders = async (uid) => {
    let orderData = {};
    
    await userOrdersCollection.doc(uid).get()
    .then((doc) => {
        orderData = doc.data();
    })
    .catch((error) => {
        return error
    });

    return orderData
}

// Function to get order details from firestore
export const getOrderDetails = async (order_id) => {
    let orderData = {};
    
    await ordersCollection.doc(order_id).get()
    .then((doc) => {
        orderData = doc.data();
    })
    .catch((error) => {
        return error
    });

    return orderData
}
