<template>
    <div class="body-container-wrapper">
        <div class="body-container">
            <h2 class="account-header">My Account</h2>
            <div class="account-container-wrapper">
                <div class="account-sidebar">
                    <ul class="sidebar-wrapper">
                        <li class="account-sidebar-item" @click="toggleActive('profile', 0)" :class="[active=='profile' ? 'active-sidebar' : '']">Profile</li>
                        <li class="account-sidebar-item" @click="toggleActive('orders', 1)" :class="[active=='orders' ? 'active-sidebar' : '']">My Orders</li>
                        <li class="account-sidebar-item" @click="toggleActive('change-password', 2)" :class="[active=='change-password' ? 'active-sidebar' : '']">Change Password</li>
                        <li class="account-sidebar-item sidebar-logout" @click="logOutUser()">Log Out</li>
                        <li class="sidebar-slider" :style="sliderStyle"></li>
                    </ul>
                </div>
                <div class="account-body">
                    
                    <div v-if="active=='profile'">
                        <div class="account-desc-container">
                            <p class="account-title">Account Information</p>
                            <p class="account-subtitle">Manage and update your account information here</p>
                        </div>
                        <div class="account-input-container">
                            <div class="input-box-wrapper">
                                <p class="card-subtitle">Full Name</p>
                                <input type="text" name="name" class="input-box" v-model="name">
                            </div>
                            <div class="input-box-wrapper-grid-2">
                                <div class="input-box-wrapper">
                                    <p class="card-subtitle">Email Address</p>
                                    <input type="email" name="email" class="input-box" disabled style="color: rgb(58 58 58 / 50%)" v-model="email">
                                </div>
                                <div class="input-box-wrapper">
                                    <p class="card-subtitle">Phone Number</p>
                                    <input type="string" name="phone" class="input-box" v-model="phone">
                                </div>
                            </div>
                            <div class="input-box-wrapper">
                                <p class="card-subtitle">Street Address</p>
                                <input type="text" name="" class="input-box" v-model="address.streetAddress">
                            </div>
                            <div class="input-box-wrapper">
                                <p class="card-subtitle">Apartment, suite, etc.</p>
                                <input type="text" name="apartment" class="input-box" v-model="address.apartment">
                            </div>
                            <div class="input-box-wrapper-grid-2">
                                <div class="input-box-wrapper">
                                    <p class="card-subtitle">City</p>
                                    <input type="text" name="city" class="input-box" v-model="address.city">
                                </div>
                                <div class="input-box-wrapper">
                                    <p class="card-subtitle">State</p>
                                    <input type="text" name="state" class="input-box" v-model="address.state">
                                </div>
                            </div>
                            <div class="input-box-wrapper-grid-2">
                                <div class="input-box-wrapper">
                                    <p class="card-subtitle">Country</p>
                                    <div class="select-input-wrapper">
                                        <select name="country" id="" class="select-input">
                                            <option value="United States" selected="selected">United States</option>
                                            <option value="Canada">Canada</option>
                                        </select>
                                        <span class="select-input-icon-wrapper"><svg xmlns="http://www.w3.org/2000/svg" class="select-input-icon" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></span>
                                    </div>
                                </div>
                                <div class="input-box-wrapper">
                                    <p class="card-subtitle">Zip</p>
                                    <input name="zip" class="input-box" type="text" maxlength="5" v-model="address.zip">
                                </div>
                            </div>
                            <div class="btn account-update-btn" @click="updateUserInfo()">
                                <p v-if="isSubmitting" style="height: 27px;position: relative;"><svg class="center" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px" height="28px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="/* margin-top: 20px; */background: none;shape-rendering: auto;position: relative;width: 100%;text-align: center;height: 29px;/* top: 4px; */"><circle cx="50" cy="50" fill="none" stroke="#fff" stroke-width="8" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(274.643 50 50)"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></circle></svg></p>
                                <p v-if="!isSubmitting" class="sign-in-text">Update</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="active=='orders'">
                        <div class="account-desc-container">
                            <p class="account-title">Order History</p>
                            <p class="account-subtitle">View all your orders here</p>
                        </div>
                        <p v-if="orderList==undefined||orderList.length==0" style="text-align: center;font-size: 13px;text-transform: uppercase;font-weight: 500;">No Orders</p>
                        <div class="account-input-container" v-if="orderList!=undefined">
                            <div class="order-info-card">

                                <div class="order-header-box">
                                    <p class="order-header-text">Order number</p>
                                    <p class="order-header-text">Order date</p>
                                    <p class="order-header-text">Order total</p>
                                    <p class="order-header-text">Order status</p>
                                </div>

                                <div class="orders-list">
                                    
                                    <div v-for="item in orderList" :key="item">
                                        <OrderItem :orderInfo="item"/>
                                    </div>

                                </div>

                                
                                
                            </div>
                        </div>
                    </div>

                    <div v-if="active=='change-password'">
                        <div class="account-desc-container">
                            <p class="account-title">Change Password</p>
                            <p class="account-subtitle">Update your account password (for email signup only)</p>
                        </div>
                        <div class="account-input-container">
                            <div class="input-box-wrapper">
                                <p class="card-subtitle">New Password</p>
                                <input type="password" name="" class="input-box" v-model="password">
                            </div>
                            <div class="input-box-wrapper">
                                <p class="card-subtitle">Confirm Password</p>
                                <input type="password" name="" class="input-box" v-model="confirmPassword">
                            </div>

                            <div class="btn account-update-btn" @click="updateUserPassword()">
                                <p v-if="isSubmitting" style="height: 27px;position: relative;"><svg class="center" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px" height="28px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="/* margin-top: 20px; */background: none;shape-rendering: auto;position: relative;width: 100%;text-align: center;height: 29px;/* top: 4px; */"><circle cx="50" cy="50" fill="none" stroke="#fff" stroke-width="8" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(274.643 50 50)"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></circle></svg></p>
                                <p v-if="!isSubmitting" class="sign-in-text">Update Password</p>
                            </div>

                        </div>
                    </div>
                
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase';
import { getuserOrders } from '@/apis/order';
import OrderItem from '@/components/account/OrderItem.vue'
import { logoutUser, getUserInfo, updateUser, updatePassword } from '@/apis/account';

export default {
    name: "Account",
    components: { OrderItem },
    data(){
        return{
            isSubmitting: false,
            active: 'profile',
            sliderStyle: {},
            uid: undefined,
            name: '',
            email: '',
            phone: '',
            address: {},
            password: '',
            confirmPassword: '',
            hasFetchedOrders: false,
            orderList: undefined
        }
    },
    mounted(){
        this.checkAuthentication();
    },
    methods:{
        checkAuthentication(){
            let self = this;
            firebase.auth().onAuthStateChanged((user) => {
                if (user != null) {
                   self.uid = user.uid;
                   self.checkUrlParams();
                   self.getUserInfo();
                }
                else{
                    window.location.href = "/sign-in";
                }
            });
        },
        checkUrlParams(){
            var url_string = window.location.href;
            var url = new URL(url_string);
            var ref = url.searchParams.get("ref");
            if(ref == "updatePassword"){
                this.active = "change-password";
            }
            else if(ref == "orders"){
                this.active = "orders";
                if(!this.hasFetchedOrders){
                    this.getOrders();
                }
            }
        },
        async getOrders(){
            this.hasFetchedOrders = true;
            if(this.uid!=undefined){
                var orders  = await getuserOrders(this.uid);
                this.orderList = orders["orders"];
            }
        },
        async getUserInfo(){
            let user = await getUserInfo(this.uid);
            this.name = user.name;
            this.email = user.email;
            this.phone = user.phone;
            if(user.phone!=undefined){
                this.phone = user.phone;
            }
            if(user.addresses[0]!=undefined){
                this.address = user.addresses[0];
            }
        },
        async updateUserInfo(){
            this.isSubmitting = true;

            let data = {
                "name": this.name,
                "phone": this.phone,
                "addresses": [this.address]
            }
            var status = await updateUser(this.uid, data);
            if(status){
                this.$root.$refs['snackbar'].showToast("Account details updated.", "#33a854");
                this.isSubmitting = false;
            }
            else{
                this.$root.$refs['snackbar'].showToast("Account updation failed", "#e52627");
                this.isSubmitting = false;
            }

        },
        async updateUserPassword(){
            if(this.password==undefined||this.password.length<6){
                this.$root.$refs['snackbar'].showToast("Password should be atleast 6 characters", "#e52627");
            }
            else if(this.confirmPassword==undefined||(this.password!=this.confirmPassword)){
                this.$root.$refs['snackbar'].showToast("Passwords do not match", "#e52627");
            }
            else{
                this.isSubmitting = true;

                var status = await updatePassword(this.password);
                if(status == "success"){
                    this.$root.$refs['snackbar'].showToast("Password Updated", "#33a854");
                    this.isSubmitting = false;
                    this.password = '';
                    this.confirmPassword = '';
                }
                else{
                    if(status["code"] == "auth/requires-recent-login"){
                        this.$root.$refs['snackbar'].showToast("Please sign in again to change your password.", "#e52627");
                        this.isSubmitting = false;
                        setTimeout(function(){
                            window.location.href = "/sign-in?redirect=updatePassword";
                        }, 3000);
                    }
                    else{
                        this.$root.$refs['snackbar'].showToast("Something went wrong", "#e52627");
                        this.isSubmitting = false;
                    }
                }
            }
        },
        toggleActive(val, index){
            this.active = val;
            if(window.innerWidth > 600){
                this.sliderStyle = {"left": index*33.3+"%"}
            }
            else{
                this.sliderStyle = {"left": index*150+"px"}
            }
            if(!this.hasFetchedOrders){
                this.getOrders();
            }
        },
        async logOutUser(){
			var logoutStatus = await logoutUser();

			if(logoutStatus){
				this.$root.$refs['snackbar'].showToast("User Logged Out", "#33a854");
                window.location.href = "/";
			}
			else{
				this.$root.$refs['snackbar'].showToast("Something went wrong", "#e52627");
			}
		}
    }
}
</script>

<style scoped>
    .body-container-wrapper{padding: 0 15px;}
    .body-container{position: relative;width: 100%;min-height: calc(100vh - 300px);margin: auto;max-width: 1280px;padding-top: 32px;}
    .account-header{text-transform: capitalize;padding: 0 12px;margin-bottom: 12px;text-align: left;line-height: 1.5;font-size: 22px;font-weight: 500;margin: 0;}
    .account-container-wrapper{padding-bottom: 48px;display: grid;grid-template-columns: 1fr 3fr;margin-top: 32px;}
    .sidebar-wrapper{position: sticky;top: 80px;}
    .account-sidebar-item{padding: 14px 20px;border-radius: 4px;cursor: pointer;}
    .account-sidebar-item:hover{background-color: #f5f6f8;}
    .active-sidebar{background-color: #f5f6f8;font-weight: 500;}
    .account-body{margin-left: 72px;box-shadow: 0px 1px 4px 0 rgb(0 0 0 / 18%), 0 1px 2px 0 rgb(0 0 0 / 6%);border-radius: 6px;background-color: #fff;padding: 24px;}
    .sidebar-slider{display: none;}

    .account-desc-container{margin-bottom: 32px;}
    .account-title{font-weight: 500;margin: 0;font-size: 17px;}
    .account-subtitle{font-size: 13px;line-height: 18px;color: #a1a1a1;margin: 0;}
    .input-box-wrapper{margin-bottom: 16px;}
    .input-box-wrapper-grid-2{display: grid;grid-template-columns: 1fr 1fr;grid-gap: 24px;}
    .input-box-wrapper-grid-3{display: grid;grid-template-columns: 1fr 1fr 1fr;grid-gap: 24px;}
    .card-subtitle{font-size: 13px;line-height: 18px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;white-space: nowrap;color: #635c5c;font-weight: 500;margin: 0;margin-bottom: 4px;}
    .input-box{box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 5%);}
    .input-box:focus{outline: 0;box-shadow:0 0 0 2px rgba(66,153,225,.5);border-color:#63b3ed;}
    .account-update-btn{margin: 0px;min-width: 150px;margin-top: 12px;padding: 7px 16px;line-height: 27px;}
    

    .select-input-wrapper{position: relative;margin-top: 8px;}
    .select-input{width: 100%;font-size: 14px;line-height: 20px;padding: 8px 40px 8px 12px;background-color: #fff;border: 1px solid #d1d5db;border-radius: .375rem;position: relative;box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 5%);cursor: pointer;outline: none;-moz-appearance: none;-webkit-appearance: none;appearance: none;}
    .select-input-val-wrapper{display: flex;align-items: center;}
    .select-input-val{overflow: hidden;text-overflow: ellipsis;white-space: nowrap;display: block;}
    .select-input-icon-wrapper{display: flex;position: absolute;pointer-events: none;align-items: center;padding-right: 8px;margin-left: 12px;right: 0;top: -1px;bottom: 0;}
    .select-input-icon{color: #9ca3af;width: 20px;height: 20px;display: block;}
    .select-input-ul{font-size: 14px;line-height: 20px;padding: 4px 0;border-radius: 6px;font-size: 14px;line-height: 20px;padding: 4px 0;border-radius: 6px;box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);background-color: #fff;overflow: auto;width: 100%;max-height: 224px;z-index: 10;margin-top: 10px;left: 0px;position: absolute;border: 1px solid #e6e6e6;}
    .select-input-li{padding: 8px 36px 8px 12px;}
    .select-input-li:hover{background-color: #f3f4f6;}

    .order-info-card{border-radius: 6px;background-color: #f5f6f8;border: 1px solid #f1f1f1;}
    .order-main-info{display: grid;grid-template-columns: 1fr 1fr 1fr 1fr;padding: 15px 10px;margin-bottom: 24px;}
    .order-header-text{letter-spacing: 0.08px;text-transform: capitalize;color: #485056;text-align: left;padding: 12px;line-height: 16px;font-size: 14px;font-weight: 600;border-bottom: 1px solid #e5e7eb;white-space: nowrap;margin: 0;}
    .order-header-box{display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 130px;}

    @media only screen and (max-width: 1024px){
        .account-body{margin-left: 0px;}
        .account-container-wrapper{display: block;}
        .account-sidebar{margin-bottom: 24px;}
        .sidebar-wrapper{position: relative;background-color: #fff;margin: 0;padding: 0;display: inline-flex;list-style: none;overflow-x: scroll;overflow-y: hidden;border-radius: 8px 8px 0 0;width: 100%;top: unset;border-bottom: 1px solid #f1f1f1;}
        .sidebar-wrapper::-webkit-scrollbar{display: none;}
        .account-sidebar-item{cursor: pointer;float: left;width: 33.3%;text-align: center;font-size: 14px;min-width: 100px;display: block;padding: 10px 12px;padding-bottom: 12px;text-decoration: none;transition: color .15s;white-space: nowrap;}
        .sidebar-slider{display: block;position: absolute;bottom: 0;left: 0;width: 33.3%;height: 3px;background-color: #222f3e;transition: left .25s;top: 41px;border-bottom: 1px solid #f1f1f1;}
        .sidebar-logout{display: none;}
    }

    @media only screen and (max-width: 600px){
        .account-sidebar-item{min-width: 150px;}
        .sidebar-slider{width: 150px;}
        .account-body{padding: 16px;}
        .input-box-wrapper-grid-2, .input-box-wrapper-grid-3{grid-template-columns: 1fr;grid-gap: 0px;}
        .select-input{padding: 12px 40px 12px 12px;}
        .account-update-btn{width: 100%;padding: 10px 16px;font-size: 16px;line-height: 24px;max-width: 100%;}
        .order-info-card{overflow-x: scroll;}
        .order-info-card::-webkit-scrollbar{display: none;}
    }
</style>