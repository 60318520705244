import firebase from 'firebase'


// Firebase Configuration
const config = {
    apiKey: "AIzaSyDSIE2JDPz8WRGSP7ehqsIc5QMFh5JDr3Y",
    authDomain: "flikbox-2606.firebaseapp.com",
    projectId: "flikbox-2606",
    storageBucket: "flikbox-2606.appspot.com",
    messagingSenderId: "195515976423",
    appId: "1:195515976423:web:81a3bb84911f2d33183042",
    measurementId: "G-GG83DKG7QK"
};

// Firebase Initialization
//const firebaseApp = firebase.initializeApp(config);
if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

// Defining Firestore Collections
const db = firebase.firestore();
const usersCollection = db.collection("users");
//const userOrdersCollection = db.collection("userOrders");
//const userInfoCollection = db.collection("userInfo");



// Firebase function to logout user
export const logoutUser = async () => {
    const status = await firebase.auth().signOut()
    .then(() => {
        return true;
    })
    .catch(() => {
        return false
    });
    return status
}

// Firebase function to update user password
export const updatePassword = async (newPassword) => {
    const user = firebase.auth().currentUser;
    
    const status = await user.updatePassword(newPassword)
    .then(() => {
        return "success";
    })
    .catch((error) => {
        return error
    });
    return status
}

// Function to get user data from firestore
export const getUserInfo = async (uid) => {
    let userData = {};
    
    await usersCollection.doc(uid).get()
    .then((doc) => {
        userData = doc.data();
    })
    .catch((error) => {
        return error
    });

    return userData
}

// Function to update a user info in users table
export const updateUser = async (uid, data) => {
    const status = await usersCollection.doc(uid).update(data).then(() => {
        return true
    });
    return status
}