<template>
    <div>
        <div class="order-item">
            <div class="order-info-item">
                <p class="order-info-val">{{orderInfo.order_id}}</p>
            </div>
            <div class="order-info-item">
                <p class="order-info-val">{{generateDate(orderInfo.order_time)}}</p>
            </div>
            <div class="order-info-item">
                <p class="order-info-val">${{generatePrice(orderInfo.total_amount)}}</p>
            </div>
            <div class="order-info-item">
                <p class="order-info-val">{{orderInfo.status}}</p>
            </div>
            <p v-if="!isDetailsOpen" class="view-order-details" @click="showFullDetails()">View details ➜</p>
            <p v-if="isDetailsOpen" class="view-order-details" @click="hideFullDetails()">Hide details <span class="order-details-arrow">➜</span></p>
        </div>

        <div class="order-details-pane" :style="orderDetailsStyle">
            <div class="order-container">
                <div class="order-progress">
                    <div class="order-progress-track"></div>
                    <div id="step1" class="order-progress-step is-active is-complete">
                        Order Placed
                    </div>
                    <div id="step2" class="order-progress-step is-active">
                        Processing
                    </div>
                    <div id="step3" class="order-progress-step">
                        Shipped
                    </div>
                    <div id="step4" class="order-progress-step">
                        Delivered
                    </div>
                </div>
            </div>
            <div class="order-items-container">
                <div class="mc-item" v-for="item in orderInfo.ordered_items" :key="item">
                    <div>
                        <img class="mc-image" :src="'https://firebasestorage.googleapis.com/v0/b/flikbox-2606.appspot.com/o/assets%2Fimages%2Fproducts%2F' + item.id +'%2F1.jpg?alt=media'" alt="Cart Image">
                    </div>
                    
                    <div class="mc-product-details">
                        <div class="mc-name-price-feat-box">
                            <div class="mc-name-price-box">
                                <a :href="'/product/'+item.id"><h3 class="mc-product-name">{{item.name}}</h3></a>
                                <p class="mc-product-price">${{getItemSubtotal(item.price, item.quantity)}}</p>
                            </div>
                            <p class="mc-product-feature">{{item.size}}, {{item.color}}</p>
                            <p class="mc-product-feature">Qty: {{item.quantity}}</p>
                        </div>
                    </div>
                </div>


            </div>
            <div class="order-total-info">
                
                <div class="mc-subtotal-box">
                    <p class="subtotal-header">Subtotal</p>
                    <p class="subtotal-value">${{subTotal}}</p>
                </div>
                <div class="mc-subtotal-box">
                    <p class="subtotal-header">Shipping</p>
                    <p class="subtotal-value">${{shippingPrice}}</p>
                </div>
                <div class="mc-subtotal-box">
                    <p class="subtotal-header">Taxes</p>
                    <p class="subtotal-value">${{taxes}}</p>
                </div>

                <div class="mc-total-box">
                    <p>Order Total</p>
                    <p>${{finalPrice}}</p>
                </div>

            </div>
        </div>


    </div>
</template>

<script>


export default {
    name: 'OrderItem',
    data(){
        return{
            orderDetailsStyle: {},
            subTotal: 0.00,
            shippingPrice: 0.00,
            taxes: 0.00,
            finalPrice: 0.00,
            isDetailsOpen: false,
            processingStyle: {},
            shippedStyle: {},
            deliveredStyle: {}
        }
    },
    props:{
        orderInfo: {},
    },
    mounted(){
        this.calculatePrice();
        this.generateProgessBar();
    },
    methods:{
        generatePrice(val){
            return Math.floor(val)/100;
        },
        generateDate(val){
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            const d = new Date(parseInt(val)*1000);
            return monthNames[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        },
        showFullDetails(){
            this.orderDetailsStyle = {"display": "block"}
            this.isDetailsOpen = true;
        },
        hideFullDetails(){
            this.orderDetailsStyle = {}
            this.isDetailsOpen = false;
        },
        getItemSubtotal(price, quantity){
            return (price*quantity).toFixed(2);
        },
        /* Calculate Final Price of all Items */
		calculatePrice(){
			this.subTotal = 0.0;
            let ordered_items = this.orderInfo.ordered_items;
			for(let i=0;i<ordered_items.length;++i){
				this.subTotal = this.subTotal + (parseFloat(ordered_items[i]["price"]) * parseInt(ordered_items[i]["quantity"]))
			}
			this.finalPrice = (this.subTotal + this.shippingPrice + this.taxes).toFixed(2);
		},
        generateProgessBar(){
            if(this.orderInfo.status == "shipped"){
                document.getElementById('step2').classList.add('is-complete');
                document.getElementById('step3').classList.add('is-active');
            }
            else if(this.orderInfo.status == "delivered"){
                document.getElementById('step2').classList.add('is-complete');
                document.getElementById('step3').classList.add('is-complete');
                document.getElementById('step4').classList.add('is-complete');
            }
        }
    }
}
</script>

<style scoped>
    .order-item{display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 130px;background-color: #fff;cursor: pointer;border-bottom: 1px solid #f1f1f1;}
    .order-info-item{padding: 12px;}
    .order-info-title{font-size: 14px;line-height: 18px;color: #a1a1a1;margin: 0;}
    .order-info-val{font-size: 14px;line-height: 18px;font-weight: 500;margin: 0;text-transform: capitalize;}
    .view-order-details{margin: 0px;text-align: right;line-height: 16px;font-size: 14px;font-weight: 500;color: rgb(62, 80, 181);user-select: none;padding: 12px;}
    .order-details-arrow{display: inline-block;width: 16px;height: 16px;transform: rotate(-90deg);transform-origin: right, top;-ms-transform: rotate(-90deg);-ms-transform-origin: right, top;-webkit-transform: rotate(-90deg);-webkit-transform-origin: right, top;}

    .order-details-pane{background-color: #fff;border-radius: 0 0 4px 4px;display: none;}
    .order-items-container{padding: 20px 10px;padding-bottom: 0;}
    .order-product-item{display: grid;grid-template-columns: 80px auto;width: 100%;grid-gap: 12px;margin-bottom: 12px;padding-bottom: 12px;border-bottom: 1px solid #e9e9e9;}
    .order-item-image{height: 80px;width: 80px;-o-object-fit: cover;object-fit: cover;-o-object-position: center;object-position: center;}
    .order-product-info{position: relative;}
    .order-product-name{font-size: 14px;font-weight: 500;line-height: 18px;letter-spacing: -0.2px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;margin: 0px;}
    .order-product-qty{font-size: 13px;line-height: 18px;letter-spacing: -0.2px;color: #b1b1b1;font-weight: 500;margin: 0;margin-top: 8px;}
    .order-product-price{font-size: 13px;line-height: 18px;letter-spacing: -0.2px;color: #b1b1b1;font-weight: 500;margin: 0;margin-top: 4px;}
    .order-qty-count, .order-price-val{color: #363636;font-weight: 500;}
    .order-total-info{padding: 0px 20px;padding-bottom: 20px;}

    .mc-item{padding-bottom: 24px;display: flex;}
    .mc-image{height: 90px;object-fit: cover;object-position: center;border-radius: 6px;}
    .mc-product{display: grid;grid-template-columns: 80px auto;width: 100%;grid-gap: 12px;margin-bottom: 12px;padding-bottom: 12px;border-bottom: 1px solid #e9e9e9;}
    .mc-product-details{flex-direction: column;flex: 1 1 0%;display: flex;margin-left: 16px;}
    .mc-name-price-feat-box{position: relative;}
    .mc-name-price-box{line-height: 24px;display: flex;justify-content: space-between;}
    .mc-product-name{font-size: 16px;font-weight: 500;}
    .mc-product-price{margin-left: 16px;font-size: 14px;line-height: 20px;font-weight: 500;}
    .mc-product-feature{color: #6b7280;font-size: 14px;line-height: 20px;margin-top: 4px;}

    .order-progress {position: relative;display: flex;}
    .order-progress .order-progress-track {position: absolute;top: 5px;width: 76%;height: 5px;background-color: #dfe3e4;z-index: -1;margin-left: 12%;}
    .order-progress .order-progress-step {position: relative;width: 100%;font-size: 12px;text-align: center;}
    .order-progress .order-progress-step:last-child:after {display: none;}
    .order-progress .order-progress-step:before {content: "✓";display: block;margin: -1px auto;margin-bottom: 5px;width: 11px;height: 11px;background: #fff;border: 4px solid #fff;border-radius: 100%;color: transparent;box-shadow: 0px 1px 4px 0 rgb(0 0 0 / 18%), 0 1px 2px 0 rgb(0 0 0 / 6%);box-sizing: initial;z-index: 1;position: relative;}
    .order-progress .order-progress-step:after {content: "";position: absolute;top: 6px;left: 50%;width: 100%;transition: width 1s ease-in;height: 5px;background: #dfe3e4;}
    .order-progress .order-progress-step.is-active {color: #3f50b5;}
    .order-progress .order-progress-step.is-active:before {border: 4px solid #fff;background: #3e50b5;}
    .order-progress .order-progress-step.is-complete {color: #3f50b5;}
    .order-progress .order-progress-step.is-complete:before {font-size: 9px;color: #fff;background: #3f50b5;border: 4px solid transparent;}
    .order-progress .order-progress-step.is-complete:after {background: #3f50b5;}
    .order-container {padding: 20px 10px;background-color: #fff;}

    .mc-subtotal-box, .mc-total-box{flex: 1 1 0%;display: flex;align-items: flex-end;justify-content: space-between;font-weight: 500;padding: 6px 0px}
    .mc-total-box{border-top: 1px solid #e5e7eb;padding-top: 12px;margin-top: 8px;}
    .subtotal-header{color: #6b7280;font-size: 14px;line-height: 20px;font-weight: 400;}
    .subtotal-value{font-size: 14px;line-height: 20px;}
    .mc-header{text-align: left;line-height: 1.5;font-size: 18px;font-weight: 500;margin: 0;margin-bottom: 20px;}

    @media only screen and (max-width: 600px){
        .order-info-item, .view-order-details{background-color: #fff;}
        .order-header-text, .order-info-item{min-width: 150px;}
        .mc-item{padding: 16px 0px;}
        .mc-image{height: 80px;}
    }
</style>